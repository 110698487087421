<template>

  <div v-if="form" class="form-view-container" :style="cssVars">  
    <div class="topalert">
        <span @click="goBack" class="back"><Icon name="arrow-left" /> Voltar</span>
        <span class="label"><Icon name="eye" /> Modo visualização, as respostas não serão salvas.</span>
    </div>
    <div class="preview-container" >
        <!-- <Preview v-if="form" :fields="form.fields" :container="true" :demo="true" ref="preview"/>     -->
        <Preview2 v-if="form" :fields="form.fields" :form="form" :container="true" :demo="true" ref="preview"/>    

    </div>  
  </div>
</template>

<script>
import Preview2 from '../components/Preview2.vue'
import Preview from '@/components/Preview.vue';
import Icon from '@/components/Icon.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import { FormMixin } from '@/mixins/FormMixin';
import { uuid } from 'vue-uuid'; 
const axios = require('axios').default;


export default {
  name: 'FormView',
  mixins: [FormMixin],
  components: {
    Preview,
    Icon,
    ProgressBar, Preview2
  },
  props: {
      isInternal: false,
  },

  data: function () {
    return {
      slug: this.$route.params.slug,
      form: null,
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromRoute = from;
    })
  },

  created: function() {        
      
      // Get form data
      if(this.slug){
          this.getForm(this.slug);
      }   
      
  },

  methods: {
      goBack(){
        if(this.isInternal)
            this.$router.back()
        else
            this.$router.push({ name: 'Editor', params: { slug: this.form.slug } })
      }
  }

}

</script>

<style lang="sass">

.topalert
    background: #FFB300
    color: #000
    height: 30px
    width: 100%
    text-align: center
    display: flex
    align-items: center
    justify-content: space-between
    font-size: $fzXxs
    padding: $mgSm
    z-index: 100

    .back
        padding: $mgXxs $mgXs
        border-radius: 4px
        cursor: pointer

        &:hover
            background: rgba(0,0,0,.1)


#beacon-container
  // display: none!important

.form-view-container
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    background-size: cover
    // background-image: var(--bg)
    overflow: hidden
    flex-direction: column
    background-color: $cFormBackground
    background-image: $formBgImage
    background-size: cover
    background-position: 0 0

    // color: var(--formColor)
    // padding: $mg

.preview-container
    width: 100%
    // max-width: 800px
    height: 100%
    // padding-left: $mg
    // padding-right: $mg

</style>
