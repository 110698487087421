<script>
import Btn from './form/Btn.vue'
import Icon from './Icon.vue'
    export default {
        components: { Icon, Btn },
        name: "AlertBar",
        props: {
            type: String,
        },

        mounted () {
            if(sessionStorage.getItem("hideAlertBar")) this.active = false
        },

        data() {
            return {
                active: true
            }
        },

        methods: {
            closeBar() {
                this.active = false    
                sessionStorage.setItem("hideAlertBar", true)            
            }
        },
        
    }
</script>

<template>
    <div v-if="active" :data-type="type" class="alert-bar-comp">
        <div class="content">
            <slot></slot>
        </div>
        <div class="close"><Btn @click.native="closeBar" layout="icon" type="warning"><Icon name="times" /></Btn></div>
    </div>
</template>


<style lang="sass" scoped>
    .alert-bar-comp
        background: lighten($cWarning, 41%)
        color: darken($cWarning, 10%)
        // padding: $mgXs $mgSm 
        font-size: $fzXs
        display: flex
        align-items: center
        justify-content: center
        position: relative
        height: 45px

        .close
            margin-left: auto
            width: 30px
            position: absolute
            right: $mgSm

        &[data-type='danger']
            background: lighten($cDanger, 41%)
            color: darken($cDanger, 10%)


</style>