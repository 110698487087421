<script>
import Btn from '../components/form/Btn.vue'
    import AlertBar from '../components/AlertBar.vue'
    export default {
        name: "MessagesBar",
        components: { AlertBar, Btn },
        props: {
            messages: Array,
        },  
        
        computed: {
            respondentsAlert() {
                return parseInt((this.$user.limits.consume.respondents.used / this.$user.limits.consume.respondents.limit) * 100)
            },

            subscriptionAlert(){
                if(this.$user.subscriptions?.status == "past_due") return true
            }
        },
    }
</script>

<template>
    <div class="messages-bar-comp">
        <!-- Subscription Past Due -->
        <AlertBar v-if="subscriptionAlert" type="danger">
            <p>🤔 Não conseguimos <strong>renovar sua assinatura</strong>. Evite o bloqueio da sua conta <Btn :link="{name: 'userSettings'}" size="xs" layout="outline" type="danger">Atualizar pagamento</Btn></p>
        </AlertBar>

        <!-- Account Limits -->
        <AlertBar v-if="respondentsAlert > 89">
            <p>🎉 Genial, você já recebeu {{respondentsAlert}}% das respostas esperadas esse mês <Btn :link="{name: 'userSettings'}" size="xs" layout="outline" type="warning">Atualize seu plan</Btn></p>
        </AlertBar>
    </div>
</template>


<style lang="scss" scoped>

</style>