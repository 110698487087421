<template>
    <div v-if="ready" class="dashboard">

        <MessagesBar />
        <TopHeader />

        <!-- Dashboard -->
        <main class="container main-content mt64">
            <div class="page-header">
                <div class="title">
                    <h1 class="page-title title-xl mb8">Seus formulários</h1>
                    <p class="page-description title-sm light">{{totalFormsString}}</p>
                </div>
                <div v-tooltip="'Seu plano permite ' + $user.limits.consume.respondents.limit.toLocaleString() + ' respostas mensais'" class="limits">
                    <div class="progress-bar mb8"><ProgressBar :position="$user.limits.consume.respondents.used" :total="$user.limits.consume.respondents.limit" layout="clean" type="scale"  /></div>
                    <div class="limit-legend">{{$user.limits.consume.respondents.available.toLocaleString()}} respostas disponíveis</div>
                </div>
            </div>
        </main>

        <div class="container whats-new mt64 mb32">
            <a href="https://malleable-scorpio-74d.notion.site/Respondi-O-que-tem-de-novo-b1f59364d3fe4ce4b4d41da9e2a351b9" target="_blank" class="call-out">
                <strong>📄 Novidades de maio!</strong> Campo para <u>Termos de Uso</u>, trakeamento de <u>UTMs</u> e melhorias de <u>arquivos anexos</u>.</a>
        </div>

        <!-- Forms list -->
        <section class="container forms-list mb64">            

            <article v-for="form in forms" :key="form.slug" :style="formStyle(form.style)" class="form-item card click">
                <div class="context-menu"><ContextMenu @context:action="formContext($event, form)" :options="[{label: 'Duplicar', value: 'duplicate'}, {label: 'Mover para time', value: 'transfer'}]" /></div>
                <router-link :to="{ name: 'EditorAnswers', params: { slug: form.slug }}" class="form-link" tag="div">
                    <h3 class="form-title title">{{form.name}}</h3>
                    <!-- <div class="form-tags mt8"><span class="tag">Coworking Brasil</span></div> -->
                    <div class="form-item-footer">
                        <div class="form-answers">{{ totalAnswers(form.respondents_count) }}</div>
                        <router-link :to="{ name: 'FormPreview', params: { slug: form.slug }}" tag="div" class="form-view" :style="{'background-color': form.style.color, 'color': getContrastColor(form.style.color)}"><Icon name="eye" /> ver</router-link>
                    </div>

                </router-link>
            </article>
           
            <router-link :to="{name: 'EditorNew'}" tag="div" class="form-item card-new-item click">
                <div class="icon">+</div>
                <div class="icon-legend">Criar formulário.</div>
            </router-link>
           
        </section>


        <!-- Move team modal -->
        <Modal v-if="trasnferForm" title="Mover formulário para..." size="sm" :active="trasnferForm ? true : false" @modal-close="transferModalClose">
            <p class="mb16">O formulário <b>{{trasnferForm.name}}</b> será movido para o time:</p>
            <SelectField v-model="transferFormTo" :options="teamsOptions" />
            <Callout v-if="!$user.teams.length > 0" class="mt16">Você não possui nenhum time. <router-link :to="{name: 'UserTeams'}" class="link" >Crie um time! 🎉</router-link></Callout>
            <Callout v-else-if="trasnferForm.user_id != $user.id" type="warning" class="mt16">Somente o autor do formulário pode movê-lo para outro time.</Callout>
            <div v-else class="actions mt16">
                <Btn @click.native="transferTo()" :loading="working.moveform" class="mr8">Mover</Btn>
                <Btn @click.native="transferModalClose()" type="secondary">Cancelar</Btn>
            </div>
        </Modal>


    </div>
</template>

<script>
import MessagesBar from './MessagesBar.vue'
import AlertBar from '../components/AlertBar.vue'
import ProgressBar from '../components/ProgressBar.vue'
import Callout from '../components/Callout.vue'
import Btn from '../components/form/Btn.vue'
import SelectField from '../components/form/SelectField.vue'
import ContextMenu from '../components/form/ContextMenu.vue'
import TopHeader from '@/components/TopHeader.vue';
import {FormMixin} from '@/mixins/FormMixin.js';
import {UtilitiesMixin} from '@/mixins/UtilitiesMixin.js';
import Modal from '../components/Modal.vue';
const axios = require('axios').default;

export default {
    components: {
        TopHeader, ContextMenu,
        Modal, SelectField, Btn, Callout, ProgressBar, AlertBar, MessagesBar
    },

    mixins: [FormMixin, UtilitiesMixin],

    data: function(){
        return {
            forms: null,
            ready: null,
            trasnferForm: null,
            transferFormTo: null,
        };
    },

    computed: {
        totalFormsString() {
            if(this.forms.length == 1) return "Você possui 1 formulário.";
            if(this.forms.length > 1) return "Você possui " + this.forms.length + " formulários.";
            else return "Você ainda não possui formulários.";
        },

        brandColor(){
            if(this.form && this.form.style.color) return this.form.style.color
            else return null
        },

        bgImage(){
            if(this.form && this.form.style.background) return this.form.style.background
            else return null
        },

        teamsOptions(){
            const teams =  this.$user.teams.filter(team => {
                if(team.id != this.$user.current_team_id) return true
            })

            if(this.$user.current_team_id != null) teams.unshift({id: null, name:this.$user.name + ' (conta pessoal)'})

            return teams.map(team => {
                return {value: team.id, label: team.name }
            })
        },

        usageAlert(){
            return parseInt((this.$user.limits.consume.respondents.used / this.$user.limits.consume.respondents.limit) * 100)
        }

    },

    mounted: function(){        
        this.forms = this.getForms()
        .then((response) => {
            const totalAnswers = this.getTotalAllAnswers(response)
            this.$mixpanel.people.set({ "total_forms": response.length, "total_answers": totalAnswers })
            this.$mixpanel.identify(this.$user.id)
        })
    },

    methods: {
        formContext(event, form){
            if(event == "transfer") this.transferFormModal(form)
            if(event == "duplicate"){
                this.duplicateForm(form)
                .then(response => {
                    this.forms.unshift(response.data)
                })
            }
        },

        transferFormModal(form){
            this.transferModalOpen(form)
        },

        transferModalOpen(form){this.trasnferForm = form},
        transferModalClose(){this.trasnferForm = null},
        transferTo(){
            this.working.moveform = true
            this.moveFormToTeam(this.trasnferForm, this.transferFormTo)
            .then(response => {
                this.forms = this.getForms()
                this.transferModalClose()
                this.working.moveform = false
            })
        },


        formStyle(style){

            let cardStyle = {
                'border-color': style.color,
                'background-color': style.bgColor,
                'color': style.textColor
            }

            if(style.background?.path) cardStyle['background-image'] = 'url(' + this.$bucket + style.background.path + ')'
            
            return cardStyle

        },

        getForms(){
            return axios
                .get(process.env.VUE_APP_API_URL + 'form')
                .then(response => {
                    this.forms = response.data
                    this.ready = true
                    return response.data
                })            
                .catch(error => console.log(error))
        },

        totalAnswers: function(value) {
            if(value == 1) return value + " resposta";
            else return value + " respostas";
        },

        getTotalAllAnswers(forms){
            return forms.reduce(function(prev, cur) {
                        return prev + cur.totalAnswers;
                    }, 0);
        }

    },


}
</script>

<style lang="sass">

    .page-header
        display: flex
        justify-content: space-between
        align-items: center

        .progress-bar
            width: 160px


        .limit-legend
            font-size: $fzXxs
            color: $cG4
            text-align: right

    .whats-new

        .call-out
            display: block
            background: rgba(#651FFF, .1)
            font-size: $fzXxs
            padding: $mgSm $mgSm
            border-radius: 4px
            color: darken(#651FFF, 10%)
            opacity: .7
            transition: all .3s
            line-height: 120%

            &:hover
                color: #651FFF
                opacity: 1



    // Form list
    .forms-list
        display: grid
        grid-template-columns: 25% 25% 25% 25%
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
        grid-auto-rows: 140px
        grid-gap: $mg
        padding-bottom: $mgLg

        .form-item
            display: flex
            flex-direction: column
            padding: $mgSm
            background-size: cover
            position: relative

        .form-link
            display: flex
            flex-direction: column
            height: 100%
        
        .form-title
            font-weight: 400
            line-height: 120%

        .tag
            display: inline-block
            font-size: $fzXxs
            color: darken($cPrimary, 10%)
            background: lighten($cPrimary, 55%)
            padding: $mgXxs $mgXs
            border-radius: 10px
        
        .form-item-footer
            display: flex
            justify-content: space-between
            margin-top: auto
            font-size: $fzXs

            .form-answers
                padding: $mgXxs 0

            .form-view
                padding: $mgXxs
                background: $cG2
                border-radius: 5px

        .context-menu
            position: absolute
            right: $mgXs
            top: $mgXs
            z-index: 100



        // Card
        .card
            box-shadow: 0px 1px 5px 1px rgba(0,0,0, .1)
            border-radius: 0 5px 5px 0
            // border-left: 4px solid $cPrimary

            &.click
                &:hover
                    box-shadow: 0px 1px 20px 3px rgba(0,0,0, .1)

            

        .card-new-item
            // background: $cG1
            border-radius: 4px
            border: 1px solid $cG3
            align-items: center
            justify-content: center
            color: $cG3

            &:hover
                color: $cPrimary
                border: 1px solid $cPrimary

            .icon
                font-weight: bold
                font-size: $fzXxl






</style>